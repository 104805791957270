import { Pipe, PipeTransform } from "@angular/core";
import { LoyaltyCardUser } from "@app-cmc/models";

@Pipe({
  name: "formatLoyaltyCardUserDetails"
})
export class FormatLoyaltyCardUserDetailsPipe implements PipeTransform {
  transform(item: LoyaltyCardUser): string {
    const { firstName, phone, email } = item;
    const values = [firstName, phone, email].filter(Boolean);

    return values.join(" | ");
  }
}
