import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CFSConnectionModalComponent, HeaderComponent, HeaderSelectLocationComponent } from "@app-cmc/core/components/layout";
import { SpinnerService } from "@app-cmc/shared/components";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { OAuthModule } from "angular-oauth2-oidc";
import { CfsCommunicationPackModule } from "cfs-communication-pack";
import { CLEARLINE_API_CONFIG_TOKEN, ClearlineApiModule } from "clearline-api";
import { CoreKitConfig, CoreKitModule } from "core-kit";
import { FilterSelectComponent } from "ui-kit";
import { environment } from "../../environments/environment";
import { AccountService } from "./services/account.service";
import { AppConfigService } from "./services/app-config.service";
import { AuthGuard } from "./services/auth-guard";
import { AuthService } from "./services/auth.service";
import { DataService } from "./services/data.service";
import { GlobalErrorHandler } from "./services/global-error.service";
import { AuthInterceptor, TimingInterceptor } from "./services/interceptors";
import { LandingGuard } from "./services/landing.guard";
import { LogPublishersService, LogService } from "./services/logging";
import { UtilityService } from "./services/utility.service";
import { TranslocoRootModule } from "./transloco/transloco-root.module";

export function appconfigServiceFactory(appService: AppConfigService): () => Promise<any> {
  return () => appService.getAppData();
}

const coreKitConfig: CoreKitConfig = {
  baseName: environment.baseName,
  apiName: environment.apiName,
  isProduction: environment.production,
  authConfig: <IAuthConfig>environment.authConfig,
  redirectUrlKey: "marketingCenterUrl"
};

@NgModule({
  declarations: [HeaderComponent, HeaderSelectLocationComponent, CFSConnectionModalComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    OAuthModule.forRoot(),
    CoreKitModule.forRoot(coreKitConfig),
    ClearlineApiModule,
    TranslocoRootModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    FilterSelectComponent,
    CfsCommunicationPackModule
  ],
  exports: [RouterModule, TranslocoRootModule, HeaderComponent, HeaderSelectLocationComponent, FilterSelectComponent],
  providers: []
})
export class CoreModule {
  // forRoot allows to override providers
  // https://angular.io/docs/ts/latest/guide/ngmodule.html#!#core-for-root
  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AuthService,
        AccountService,
        AuthGuard,
        DataService,
        LogService,
        LogPublishersService,
        UtilityService,
        SpinnerService,
        AppConfigService,
        LandingGuard,
        { provide: APP_INITIALIZER, useFactory: appconfigServiceFactory, deps: [AppConfigService], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TimingInterceptor, multi: true },
        {
          provide: CLEARLINE_API_CONFIG_TOKEN,
          useFactory: (appConfigService: AppConfigService) => ({
            apiUrl: appConfigService.apiUrl
          }),
          deps: [AppConfigService]
        },
        { provide: ErrorHandler, useClass: GlobalErrorHandler }
      ]
    };
  }
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error("CoreModule is already loaded. Import it in the AppModule only");
    }
  }
}
