<div class="issue-points-modal" *transloco="let t; read: 'features.issuePoints'">
  <div class="modal-header">
    <h5 class="modal-title">
      {{ t("title") }}
    </h5>

    <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="dismiss()"></button>
  </div>

  <div class="modal-body">
    <div class="modal-main-content" [formGroup]="form">
      <div *ngIf="!loading; else spinner">
        <div class="coupon-container justify-content-between">
          <app-loyalty-card *ngIf="selectedCard" class="coupon" [item]="selectedCard" [type]="'simple'"> </app-loyalty-card>

          <lib-loyalty-program-balance
            *ngIf="loyaltyUser"
            size="S"
            [value]="balanceValue"
            [label]="balanceLabel"
          ></lib-loyalty-program-balance>
        </div>

        <div class="modal-form">
          <ng-select
            *ngIf="!isUsed && !isAutoSearch"
            bindLabel="uniqueCode"
            bindValue="uniqueCode"
            formControlName="search"
            [items]="loyaltyUserOptions$ | async"
            [placeholder]="t('placeholder.search_user')"
            [typeahead]="searchInput$"
          >
            <ng-template ng-option-tmp let-item="item">
              <span *ngIf="item">
                {{ item | formatLoyaltyCardUserDetails }}
              </span>
            </ng-template>
          </ng-select>
        </div>
      </div>

      <div *ngIf="!loyaltyUser && !nothingFound && !loading" class="search-block">
        <img alt="" class="search-block__img" src="assets/images/load-img.svg" />

        <div class="search-block__text">
          {{ t("search_text") }}
        </div>
      </div>

      <div *ngIf="nothingFound && !loading" class="search-empty-block">
        <span class="content-text" *transloco="let t; read: 'features.issueCoupon'">
          {{ t("nothing_found") }}
        </span>
      </div>

      <div *ngIf="loyaltyUser && !nothingFound && !loading" class="modal-table-content">
        <app-issue-points-additional-details
          [loyaltyCard]="selectedCard"
          [loyaltyUser]="loyaltyUser"
        >
        </app-issue-points-additional-details>

        <div *ngIf="selectedCard" class="card-points d-flex mt-3">
          <div class="me-3 w-100">
            <app-input
              *ngIf="selectedCard.type !== 'stampcard'"
              type="number"
              [control]="pointsControl"
              [isRequired]="true"
              [label]="t('add_points')"
              [placeholder]="t('placeholder.add_points')"
            ></app-input>

            <lib-dropdown
              *ngIf="selectedCard.type === 'stampcard'"
              bindValue="value"
              bindLabel="label"
              [control]="pointsControl"
              [items]="stampsOptions"
              [label]="t('add_stamps')"
              [placeholder]="t('add_stamps')"
              [appendOptionsToBody]="true"
            ></lib-dropdown>
          </div>

          <app-input
            *ngIf="isAmbassadorFeatureAvailable"
            [control]="ambassadorId"
            [label]="t('ambassadorId')"
            [placeholder]="t('ambassadorId')"
          ></app-input>
        </div>

        <app-card-rewards-block class="card-rewards-block" [rewards]="rewards"> </app-card-rewards-block>

        <lib-loyalty-program-rewards
          [rewards]="activeRewards"
          [isReadOnly]="true"
        ></lib-loyalty-program-rewards>
      </div>
    </div>

    <div class="modal-footer inner two-actions" *transloco="let t; read: 'common.controls'">
      <app-qr-code-reader-button
        class="btn p-0"
        [buttonText]="'qrcode.scan' | transloco"
        (scanModalOpened)="onScanModalOpened()"
        (scanResult)="scan($event)"
      ></app-qr-code-reader-button>

      <button *ngIf="!isUsed"
        type="button"
        class="btn btn-main"
        [class.disabled]="!pointsControl.valid || loading"
        (click)="submit()"
      >
        {{ t("submit") }}
      </button>

      <button *ngIf="isUsed" (click)="dismiss()" type="button" class="btn btn-main">
        {{ t("done") }}
      </button>
    </div>

    <ng-template #spinner>
      <div class="spinner-wrapper">
        <span class="spinner-border spinner-border-sm mr-1"> </span>
      </div>
    </ng-template>
  </div>
</div>
