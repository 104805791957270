import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AccountService } from "@app-cmc/core";
import { FeatureModalBaseComponent } from "@app-cmc/features/feature-modal-base.component";
import {
  FeatureTypes,
  Location,
  LoyaltyCard,
  LoyaltyCardReward,
  LoyaltyCardUpdateAmount,
  LoyaltyCardUpdateResult,
  LoyaltyCardUser,
  LoyaltyCardUsersSearchDto,
  LoyaltyCardUsersSearchResultDto
} from "@app-cmc/models";
import { CFSConnectionService, LocationService, LoyaltyCardService } from "@app-cmc/services";
import { ToastService } from "@app-cmc/shared/components/app-toaster";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoService } from "@ngneat/transloco";
import { NULL_OBSERVABLE } from "clearline-api";
import { ItemWithLabel, RewardCardView, rewardsDtoToRewardCardList } from "clearline-common";
import { forkJoin, Observable, of, Subject } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, finalize, map, switchMap, take, takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "app-issue-points-modal",
  templateUrl: "./issue-points-modal.component.html",
  styleUrls: ["./issue-points-modal.component.scss"]
})
export class IssuePointsModalComponent extends FeatureModalBaseComponent implements OnInit, OnDestroy {
  readonly locationId = +this.accountService.user.locationId;
  readonly stampsOptions: ItemWithLabel<number>[] = this.getStampsOptions();

  isAmbassadorFeatureAvailable = false;
  isValidated = false;
  isUsed = false;
  isAutoSearch = false;
  loading = false;
  nothingFound = false;
  loyaltyUser: LoyaltyCardUser;
  loyaltyUserOptions$: Observable<LoyaltyCardUser[]>;
  selectedCard: LoyaltyCard;
  rewards: Array<LoyaltyCardReward>;
  activeRewards: RewardCardView[] = [];
  balanceLabel = "";
  balanceValue = 0;
  searchInput$ = new Subject<string>();
  form: FormGroup;

  get isValid() {
    return this.form.valid;
  }

  get ambassadorId(): FormControl {
    return this.form.controls.ambassadorId as FormControl;
  }

  get pointsControl(): FormControl {
    return this.form.controls.points as FormControl;
  }

  get searchControl(): FormControl {
    return this.form.controls.search as FormControl;
  }

  private loyaltyUsers: LoyaltyCardUser[] = [];
  private searchMinLength = 3;
  private currentLocation: Location;
  private unsubscribe$ = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    public accountService: AccountService,
    private locationService: LocationService,
    public translateSvc: TranslocoService,
    public toastSvc: ToastService,
    private loyaltyCardService: LoyaltyCardService,
    private cfsService: CFSConnectionService
  ) {
    super(activeModal, FeatureTypes.IssuePoints);
  }

  ngOnInit(): void {
    this.setForms();
    this.setLoyaltyCards();
  }

  onScanModalOpened(): void {
    this.cfsService.toggleScanQrCodeModal(true).pipe(take(1)).subscribe();
  }

  scan(userCardLink: string) {
    this.onScanModalClose();
    const invalidUrlMessage = this.translateSvc.translateObject("features.issuePoints.notValidUrl");

    if (!userCardLink || !userCardLink.startsWith("http")) {
      this.toastSvc.danger(invalidUrlMessage);
      return;
    }

    const params = userCardLink.split("/");
    const userCode = params.pop();
    const cardCode = params.pop();
    const card = this.selectedCard.code === cardCode ? this.selectedCard : null;

    if (!card) {
      this.toastSvc.danger(invalidUrlMessage);
      return;
    }

    this.onSelectChanged(card);
    this.searchByCode(userCode);
  }

  submit() {
    this.loading = true;
    const location = this.selectedCard.locations.find((l) => l.name === this.currentLocation.companyName);

    if (location) {
      const { points, ambassadorId } = this.form.getRawValue();
      const payload: LoyaltyCardUpdateAmount = {
        ambassadorId,
        merchantid: `${location.id}`,
        amount: points,
        user: this.loyaltyUser.id,
        loyaltycard: this.selectedCard.id,
        locationId: this.currentLocation.id
      };

      this.loyaltyCardService
        .addUserLoyaltyCardAmount(payload)
        .pipe(
          take(1),
          finalize(() => (this.loading = false))
        )
        .subscribe(
          (result: LoyaltyCardUpdateResult) => {
            this.loading = false;

            if (result?.loyaltycard) {
              const targetI18nKey = this.selectedCard.type === "stampcard" ? "loyaltyProgram.stamps" : "loyaltyProgram.points";
              const target = this.translateSvc.translate(targetI18nKey);

              this.isUsed = true;
              this.selectedCard.type === "stampcard"
                ? (this.loyaltyUser.stamps = result.newAmount)
                : (this.loyaltyUser.points = result.newAmount);
              this.rewards = result.rewardsAdded;

              this.updateUserBalance();
              this.toastSvc.success(
                this.translateSvc.translate("loyaltyProgram.addSuccessMessage", {
                  value: result.addedAmount,
                  target
                })
              );
            } else {
              this.toastSvc.danger(this.translateSvc.translate("loyaltyProgram.errorCardMessage"));
            }
          },
          (error) => {
            const serverErrorMessage = error.error.Message;

            if (serverErrorMessage) {
              this.toastSvc.danger(serverErrorMessage);
            }
          }
        );
    } else {
      this.loading = false;

      this.toastSvc.danger(this.translateSvc.translate("loyaltyProgram.errorLocationMessage"));
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private search() {
    const searchText = this.searchControl?.value?.trim();

    this.resetBeforeSearch();
    this.resetPointsForm();
    this.loading = true;

    this.getLoyaltyCardUsers(searchText)
      .pipe(take(1))
      .subscribe(
        () => {
          this.loading = false;
          const user = this.loyaltyUsers.length ? this.loyaltyUsers[0] : null;
          this.updateLoyaltyUser(user);
        },
        () => {
          this.loading = false;
          this.nothingFound = true;
        }
      );
  }

  private updateLoyaltyUser(user: LoyaltyCardUser) {
    this.loyaltyUser = user;
    this.updateUserBalance();
  }

  private onSelectChanged(loyaltyCard: LoyaltyCard) {
    this.selectedCard = loyaltyCard;
    this.updateUserBalance();
  }

  private updateUserBalance(): void {
    this.balanceLabel = "";
    this.balanceValue = 0;
    this.activeRewards = [];

    if (this.loyaltyUser) {
      this.balanceLabel = this.selectedCard.amountOfStamps ? "stamps" : "points";
      this.balanceValue = this.selectedCard.type === "stampcard" ? this.loyaltyUser.stamps : this.loyaltyUser.points;
      this.activeRewards = rewardsDtoToRewardCardList(this.loyaltyUser.activeRewards);
    }
  }

  private onScanModalClose(): void {
    this.cfsService
      .toggleScanQrCodeModal(false)
      .pipe(take(1))
      .subscribe(() => {});
  }

  private resetBeforeSearch(): void {
    this.nothingFound = false;
  }

  private setForms(): void {
    this.form = new FormGroup({
      ambassadorId: new FormControl(null),
      points: new FormControl(null, [Validators.required, Validators.min(1)]),
      search: new FormControl(null, [Validators.required, Validators.minLength(this.searchMinLength)])
    });

    this.handleFormsValueChange();
  }

  private handleFormsValueChange(): void {
    this.pointsControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {});

    this.loyaltyUserOptions$ = this.searchInput$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((searchText: string) => {
        return !!searchText && searchText.length >= this.searchMinLength
          ? this.getLoyaltyCardUsers(searchText).pipe(map((result) => result.users))
          : of([]);
      })
    );

    this.searchControl.valueChanges.pipe(takeUntil(this.unsubscribe$), distinctUntilChanged()).subscribe((uniqueCode) => {
      const user = this.loyaltyUsers.find((u) => u.uniqueCode === uniqueCode);
      this.updateLoyaltyUser(user);
    });
  }

  private getLoyaltyCardUsers(searchText: string): Observable<LoyaltyCardUsersSearchResultDto> {
    const postData = <LoyaltyCardUsersSearchDto>{
      locationId: this.locationId,
      searchText: searchText?.trim()
    };

    return this.loyaltyCardService.getLoyaltyCardUsers(postData).pipe(
      catchError(() => of({ users: [], loyaltycard: null })),
      tap((result) => {
        this.onCardSearchChange(result);
        if (this.selectedCard) {
          this.onSelectChanged(this.selectedCard);
        }
      })
    );
  }

  private onCardSearchChange(result: LoyaltyCardUsersSearchResultDto): void {
    if (result?.loyaltycard) {
      this.selectedCard = result.loyaltycard;
      this.loyaltyUsers = result.users;
    } else {
      this.nothingFound = true;
    }
  }

  private setLoyaltyCards(): void {
    this.loading = true;

    forkJoin([
      this.locationService.getAll(),
      this.loyaltyCardService.getAccountLoyaltyCard().pipe(catchError(() => NULL_OBSERVABLE))
    ]).subscribe(([locations, loyaltyCard]) => {
      this.currentLocation = locations.find((l) => l.id === this.locationId);

      if (loyaltyCard) {
        this.onSelectChanged(loyaltyCard);
      }

      this.applyAutoSearch();
    });
  }

  private applyAutoSearch(): void {
    if (this.data?.userCardCode) {
      this.searchByCode(this.data.userCardCode);
    } else {
      this.loading = false;
    }
  }

  private resetPointsForm() {
    this.pointsControl.setValue("");
    this.clearPointsFormValidators();
  }

  private clearPointsFormValidators(): void {
    this.pointsControl.markAsUntouched();
    this.pointsControl.updateValueAndValidity();
  }

  private searchByCode(code: string): void {
    this.searchControl.setValue(code, { emitEvent: false });
    this.isAutoSearch = true;
    this.search();
  }

  private getStampsOptions(length = 5): ItemWithLabel<number>[] {
    const i18prefix = "features.issuePoints.stamps.plural";

    return Array.from({ length }, (_, i) => i + 1).map((i) => ({
      value: i,
      label: this.translateSvc.translate(i === 1 ? `${i18prefix}.one` : `${i18prefix}.other`, { count: i })
    }));
  }
}
