import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LoyaltyCard, LoyaltyCardUser } from "@app-cmc/models";

@Component({
  selector: "app-issue-points-additional-details",
  templateUrl: "./issue-points-additional-details.component.html",
  styleUrls: ["./issue-points-additional-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IssuePointsAdditionalDetailsComponent {
  @Input() loyaltyCard: LoyaltyCard;
  @Input() loyaltyUser: LoyaltyCardUser;
}
