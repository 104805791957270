import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AccountService } from "./account.service";

import { AuthService } from "./auth.service";
import { RoleTypes } from "@app-cmc/models";

@Injectable()
export class AuthGuard  {
  constructor(private authService: AuthService, private router: Router, private accountSvc: AccountService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let result = this.checkLogin();
    if (!result) {
      this.authService.login();
      return result;
    }

    result = this.checkRole(state);
    if (!result) {
      this.router.navigate(["/access-denied"]);
      return result;
    }

    result = this.hasLocation(state);
    if (!result) {
      // this.router.navigate(["/choose-location"]);
      this.router.navigate(["/"]);
      return result;
    }

    return result;
  }

  private checkRole(state: RouterStateSnapshot) {
    const result = this.authService.requredMinimumRole(RoleTypes.PartnerAdmin);
    if (result && state.url !== "/access-denied") {
      return false;
    }
    return true;
  }
  private checkLogin(): boolean {
    if (this.authService.isLoggedIn) {
      return true;
    }
    return false;
  }

  isManagerAccountConfigured(state: RouterStateSnapshot) {
    const user = this.accountSvc.user;
    const result = !user.configured;
    if (result && state.url !== "/access-denied") {
      return false;
    }
    return true;
  }

  hasLocation(state: RouterStateSnapshot) {
    const user = this.accountSvc.user;
    const result = !user.locationId;
    if (result && state.url !== "/") {
      return false;
    }
    return true;
  }
}
