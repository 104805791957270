import { NgModule } from "@angular/core";
import {
  CardRewardComponent,
  CardRewardsBlockComponent,
  IssuePointsAdditionalDetailsComponent,
  LoyaltyCardComponent,
  LoyaltyCardsComponent
} from "@app-cmc/features/issue-points";
import { NgSelectModule } from "@ng-select/ng-select";
import { LoyaltyProgramBalanceComponent, LoyaltyProgramRewardsComponent } from "clearline-common";
import { SimplebarAngularModule } from "simplebar-angular";
import { QrCodeReaderModule } from "ui-kit";
import { CommonFeatureModule } from "../common/common-feature.module";
import { IssuePointsModalComponent } from "./issue-points-modal";
import { FormatLoyaltyCardUserDetailsPipe } from "./pipes";

@NgModule({
  declarations: [
    FormatLoyaltyCardUserDetailsPipe,
    IssuePointsModalComponent,
    LoyaltyCardsComponent,
    LoyaltyCardComponent,
    IssuePointsAdditionalDetailsComponent,
    CardRewardsBlockComponent,
    CardRewardComponent
  ],
  imports: [
    CommonFeatureModule,
    NgSelectModule,
    SimplebarAngularModule,
    QrCodeReaderModule,
    LoyaltyProgramRewardsComponent,
    LoyaltyProgramBalanceComponent
  ],
  exports: [IssuePointsModalComponent]
})
export class IssuePointsModule {}
