import { NgModule } from "@angular/core";
import { NgbAccordionModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonFeatureModule } from "../common/common-feature.module";
import { DefaultTemplateFeatureModalComponent } from "./default-template-feature-modal/default-template-feature-modal.component";

@NgModule({
  declarations: [DefaultTemplateFeatureModalComponent],
  imports: [CommonFeatureModule, NgbAccordionModule]
})
export class DefaultTemplateFeatureModule {}
