import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { AppConfigService } from "@app-cmc/core";
import { AccountService } from "@app-cmc/core/services/account.service";
import { DefaultTemplateFeatureModalComponent } from "@app-cmc/features/default-template-feature";
import { IssueCouponModalComponent } from "@app-cmc/features/issue-coupon/issue-coupon-modal/issue-coupon-modal.component";
import { IssuePointsModalComponent } from "@app-cmc/features/issue-points/issue-points-modal/issue-points-modal.component";
import { ValidateCouponModalComponent } from "@app-cmc/features/validate-coupon/validate-coupon-modal";
import { CFSConnectionService, CompanyDataService, FeaturesService, LocationService } from "@app-cmc/services";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoService } from "@ngneat/transloco";
import { CFSConnectionMessage } from "cfs-communication-pack";
import { Account, AccountConfigurationLoyaltyDto, AccountsService } from "clearline-api";
import { auditTime, filter, take } from "rxjs/operators";
import { FeatureData, FeatureModel, FeatureTypes, NotificationAction, NotificationModel, RoleTypes, UserStatus } from "../models";

@Component({
  templateUrl: "dashboard.component.html",
  selector: "app-dashboard",
  styleUrls: ["dashboard.component.scss"]
})
export class DashboardComponent implements OnInit, AfterViewInit {
  menuIconActive = 1;
  userName = null;
  isLoadPage = false;
  notUserLocation: boolean = false;
  UserStatus = UserStatus;
  features: FeatureModel[] = [];
  notifications: NotificationModel[] = [];

  private currentAccount: Account;

  get enabledFeatures(): FeatureModel[] {
    return this.features.filter((f) => !f.disabled);
  }

  get isManager() {
    return this.accountService && this.accountService.user.role === RoleTypes.Manager;
  }
  get isManagerType() {
    return this.accountService && (this.isManager || this.accountService.user.role === RoleTypes.LocationManager);
  }

  constructor(
    private featureService: FeaturesService,
    private locService: LocationService,
    private ref: ChangeDetectorRef,
    private configSvc: AppConfigService,
    private titleService: Title,
    private translateSvc: TranslocoService,
    private accountService: AccountService,
    private accountsService: AccountsService,
    private companyDataService: CompanyDataService,
    private modalSvc: NgbModal,
    private cfsConnectionService: CFSConnectionService
  ) {
    this.setBrowserTitle();
  }

  ngOnInit(): void {
    this.companyDataService.notificationsChanged.subscribe((x) => {
      if (x && this.accountService.user) {
        this.notifications = x;
        if (
          this.accountService.user &&
          this.accountService.user.locationId > 0 &&
          this.accountService.user.role !== RoleTypes.LocationManager
        ) {
          this.featureService.getAvailableFeatures().subscribe((f) => (this.features = f));
        } else {
          this.checkNotUserLocations();
        }
      }
    });
    this.companyDataService.retriveData();

    this.cfsConnectionService
      .onShowWidget()
      .pipe(
        filter((v) => !!v),
        auditTime(300)
      )
      .subscribe((message: CFSConnectionMessage) => {
        const { widgetType, data } = message?.showWidgetSettings || {};
        const feature = this.enabledFeatures.find((f) => f.id === widgetType);

        this.runFeature(feature, data);
      });

    this.accountsService
      .getCurrentAccount()
      .pipe(take(1))
      .subscribe((account) => {
        this.currentAccount = account;
      });
  }

  ngAfterViewInit() {
    this.isLoadPage = true;
    this.ref.detectChanges();
  }

  checkNotUserLocations() {
    this.notUserLocation = false;

    if (this.accountService.user.sub && this.accountService.user.role === RoleTypes.LocationManager) {
      this.locService.getUserLocations(Number(this.accountService.user.sub)).subscribe((x) => {
        if (x.length > 0) {
          this.featureService.getAvailableFeatures().subscribe((f) => (this.features = f));
        } else {
          this.notUserLocation = true;
          this.userName = this.accountService.user.nickname;
        }
      });
    } else if (this.accountService.user && !this.accountService.user.locationId) {
      this.locService.getAll().subscribe((x) => {
        if (x.length === 0) {
          this.notUserLocation = true;
          this.userName = this.accountService.user.nickname;
        }
      });
    }
  }

  getActionLink(action) {
    if (this.isManager && (action & NotificationAction.addActionLink) !== 0) {
      return this.configSvc.appData.selfServicePortalUrl;
    }
    return null;
  }

  runFeature(feat: FeatureModel, data: FeatureData = {}) {
    if (!feat) return;

    if (this.modalSvc.hasOpenModals()) {
      this.modalSvc.dismissAll();
    }

    const options: NgbModalOptions = { backdrop: "static", centered: true };

    switch (feat.id) {
      case FeatureTypes.IssueCoupon: {
        const featureModal = this.modalSvc.open(IssueCouponModalComponent, {
          backdrop: "static",
          size: "l",
          centered: true,
          windowClass: "custom"
        });
        const comp: IssueCouponModalComponent = featureModal.componentInstance;
        comp.title = feat.name;
        comp.deliveryMethod = feat.deliveryMethod;
        comp.data = data;
        break;
      }
      case FeatureTypes.ValidateCoupon: {
        const featureModal = this.modalSvc.open(ValidateCouponModalComponent, {
          backdrop: "static",
          size: "lg",
          centered: true,
          windowClass: "custom"
        });
        const comp: ValidateCouponModalComponent = featureModal.componentInstance;
        comp.title = feat.name;
        comp.deliveryMethod = feat.deliveryMethod;
        comp.data = data;
        break;
      }
      case FeatureTypes.IssuePoints: {
        const featureModal = this.modalSvc.open(IssuePointsModalComponent, {
          backdrop: "static",
          size: "l",
          centered: true,
          windowClass: "custom"
        });
        const comp: IssuePointsModalComponent = featureModal.componentInstance;
        const loyalty = this.currentAccount?.configuration?.loyalty || ({} as AccountConfigurationLoyaltyDto);
        const { ambassadorFeatureAvailable, ambassadorEnabled } = loyalty;
        comp.title = feat.name;
        comp.deliveryMethod = feat.deliveryMethod;
        comp.data = data;
        comp.isAmbassadorFeatureAvailable = ambassadorFeatureAvailable && ambassadorEnabled;
        break;
      }
      case FeatureTypes.FAQ: {
        window.open("https://clearline.crunch.help", "_blank");
        break;
      }
      default: {
        const featureModal = this.modalSvc.open(DefaultTemplateFeatureModalComponent, {
          backdrop: "static",
          size: "default",
          centered: true
        });
        const comp: DefaultTemplateFeatureModalComponent = featureModal.componentInstance;
        comp.featureId = feat.id;
        comp.title = feat.name;
        comp.deliveryMethod = feat.deliveryMethod;
        comp.data = data;
        break;
      }
    }
  }

  clickMenuView(val: number) {
    this.menuIconActive = val;
  }

  setBrowserTitle() {
    this.titleService.setTitle(`${this.configSvc.appData.title}  ${this.translateSvc.translateObject("common.menu.default")}`);
  }
}
