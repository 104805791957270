<div *transloco="let t; read: 'features.issuePoints'" class="table-custom-wrapper">
  <table class="table-custom table-nowrap mb-0">
    <tbody>
      <tr class="table-custom__row">
        <th scope="row" class="table-custom__title">
          {{ t("card_title") + ":" }}
        </th>
        <td class="table-custom__value">
          <span class="table-custom__value-text">
            {{ loyaltyCard.title }}
          </span>
        </td>
      </tr>

      <tr class="table-custom__row">
        <th scope="row" class="table-custom__title">
          {{ t("user.firstName") }}
        </th>
        <td class="table-custom__value">
          <span class="table-custom__value-text">
            {{ loyaltyUser.firstName }}
          </span>
        </td>
      </tr>

      <tr class="table-custom__row">
        <th scope="row" class="table-custom__title">
          {{ t("user.lastName") }}
        </th>
        <td class="table-custom__value">
          <span class="table-custom__value-text">
            {{ loyaltyUser.lastName }}
          </span>
        </td>
      </tr>

      <tr *ngIf="loyaltyUser.email" class="table-custom__row">
        <th scope="row" class="table-custom__title">
          {{ t("user.email") }}
        </th>
        <td class="table-custom__value">
          <span class="table-custom__value-text">
            {{ loyaltyUser.email }}
          </span>
        </td>
      </tr>

      <tr *ngIf="loyaltyUser.phone" class="table-custom__row">
        <th scope="row" class="table-custom__title">
          {{ t("user.phone") }}
        </th>
        <td class="table-custom__value">
          <span class="table-custom__value-text">
            {{ loyaltyUser.phone }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
