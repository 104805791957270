import { ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";

import { NavigationEnd, Router } from "@angular/router";
import { ProfileModel } from "@app-cmc/core/models";
import { AccountService } from "@app-cmc/core/services/account.service";
import { AuthService } from "@app-cmc/core/services/auth.service";
import { BrandColorPalette, Location, RoleTypes, Subscription } from "@app-cmc/models";
import { CashRegistersService, CFSConnectionService, CompanyDataService, LocationService } from "@app-cmc/services";
import { delay, filter, take, takeUntil } from "rxjs/operators";
import { CFSConnectionModalResult, CFSConnectionSettings, ClientCashRegister } from "cfs-communication-pack";
import { Subject } from "rxjs";
import { NgbDropdown, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CFSConnectionModalComponent } from "@app-cmc/core/components/layout";
import { AppConfigService } from "@app-cmc/core/services/app-config.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, OnDestroy {
  constructor(
    private route: Router,
    private accountService: AccountService,
    private authService: AuthService,
    private companyDataService: CompanyDataService,
    private cdr: ChangeDetectorRef,
    private modalSvc: NgbModal,
    private cfsConnectionService: CFSConnectionService,
    private configSvc: AppConfigService,
    private locationService: LocationService,
    private cashRegistersService: CashRegistersService
  ) {}

  readonly mobileScreenWidth = 576;
  location: Location;
  profileUrl: string;
  loginOrLandingOnly = false;
  user: ProfileModel;
  subscription: Subscription = new Subscription();
  brandColorsPalette: BrandColorPalette;
  windowWidth: number;

  private readonly auditTimeDelay = 300;
  private _brandLogo;
  public get brandLogo() {
    return this._brandLogo;
  }

  private unsubscribe$ = new Subject<void>();

  @Input() public set brandLogo(value) {
    if (value) {
      this._brandLogo = value;
      this.cdr.detectChanges();
    }
  }

  @ViewChild("dropdownRef", { static: false, read: NgbDropdown }) ngbDropdown: NgbDropdown;

  get isAdmin() {
    return this.accountService && this.authService.requredMinimumRole(RoleTypes.PartnerAdmin);
  }

  get isManager() {
    return this.accountService && this.accountService.user.role === RoleTypes.Manager;
  }

  get isSales() {
    return this.accountService && this.accountService.user.role === RoleTypes.Sales;
  }

  get isLocationManager() {
    return this.accountService && this.accountService.user.role === RoleTypes.LocationManager;
  }

  get isMobileApp() {
    return navigator.userAgent === "CMC Mobile App";
  }

  get year() {
    return new Date().getFullYear();
  }

  get selfServicePortalUrl() {
    return this.configSvc.appData.selfServicePortalUrl;
  }

  get adminPortalUrl() {
    return this.configSvc.appData.adminUIUrl;
  }

  get periodEndDate() {
    const endDate = new Date(this.subscription.endDate);
    const dd = String(endDate.getDate()).padStart(2, "0");
    const mm = String(endDate.getMonth() + 1).padStart(2, "0");
    const yyyy = endDate.getFullYear();
    return mm + "/" + dd + "/" + yyyy;
  }

  get periodEndDays() {
    const endDate = new Date(this.subscription.endDate);
    const today = new Date();
    const difference = endDate.getTime() - today.getTime();

    return Math.ceil(difference / (1000 * 3600 * 24));
  }

  @HostListener("window:resize") handleResizeEvent() {
    this.setWindowWidth();
  }

  ngOnInit() {
    this.setWindowWidth();
    this.setCurrentCashRegister();
    this.handleOnEvents();
    this.loadScript();

    const identityUrl = this.configSvc.appData.identityUrl;
    const returnUrl = this.configSvc.appData.authConfig.redirectUrl;
    this.profileUrl = `${identityUrl}?returnUrl=${returnUrl}`;
  }
  toggleDropdown(): void {
    const isItOpenAction: boolean = this.ngbDropdown?.isOpen();

    if (isItOpenAction) {
      this.companyDataService.retriveData(true);
    }
  }

  logout() {
    this.authService.logout();
  }

  customerScreen(): void {
    const modal = this.modalSvc.open(CFSConnectionModalComponent, {
      backdrop: "static",
      size: "lg",
      centered: true,
      windowClass: "custom"
    });
    const currentCashRegister: ClientCashRegister = this.cashRegistersService.getCurrentCashRegister();
    const modalComponent: CFSConnectionModalComponent = modal.componentInstance;
    modalComponent.status = currentCashRegister.clientStatus;
    modalComponent.networkStatus = currentCashRegister.clientNetworkStatus;
    modalComponent.connectedCode = currentCashRegister.cfsCode;
    modalComponent.cashregister = currentCashRegister;
    modalComponent.cashregisterId = currentCashRegister.id;

    modal.closed
      .pipe(
        filter((result: CFSConnectionModalResult) => result?.closingReason === "confirm"),
        delay(this.auditTimeDelay),
        take(1)
      )
      .subscribe((result: CFSConnectionModalResult) => {
        const locationId: number = this.locationService.getSelectedLocationLocalStorage();
        const { oldCode, updatedCashRegister } = result;
        const { cfsCode, id } = updatedCashRegister;

        if (cfsCode && oldCode && cfsCode !== oldCode) {
          this.cashRegistersService.clearCashRegisterSubscription(cfsCode, id);
        }

        this.cashRegistersService.update(locationId);
      });
  }

  updateCFSCarousel(): void {
    const currentCashRegister: ClientCashRegister = this.cashRegistersService.getCurrentCashRegister();

    if (currentCashRegister.cfsCode) {
      const settings: CFSConnectionSettings = this.cfsConnectionService.getCFSConnectionSettings(currentCashRegister.id);

      this.cfsConnectionService.publishUpdateCarousel(settings, currentCashRegister.cfsCode);
    }
  }

  goToProfile() {
    window.location.href = this.profileUrl;
  }

  private setCurrentCashRegister(): void {
    this.cashRegistersService.init(this.accountService.userId);

    this.cashRegistersService.runStateBroadcast().pipe(takeUntil(this.unsubscribe$)).subscribe();
    this.cashRegistersService.runUpdateCashregisters().pipe(takeUntil(this.unsubscribe$)).subscribe();

    // todo: temporary not used, must to be reused after cashRegisterId and locationId will be no longer taken from the token
    this.cashRegistersService.currentCashRegister$.pipe(takeUntil(this.unsubscribe$)).subscribe((item: ClientCashRegister) => {});
  }

  private handleOnEvents(): void {
    this.route.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd)).subscribe(() => {
      this.loginOrLandingOnly = location.pathname.startsWith("/landing");
    });

    this.accountService.userChanged$.subscribe((u) => {
      this.user = u;
      if (u) {
        this.handleSubscriptionChanged();
        this.companyDataService.retriveData();
        this.brandLogo = u.logo;

        this.cashRegistersService.init(this.accountService.userId);
      }
    });
  }

  private handleSubscriptionChanged(): void {
    this.companyDataService.subscriptionChanged.pipe(takeUntil(this.unsubscribe$)).subscribe((subscription: Subscription) => {
      this.subscription = subscription;
    });
  }

  public loadScript() {
    // const node = document.createElement('script');
    // node.src = "assets/app.js";
    // node.type = 'text/javascript';
    // node.async = true;
    // document.getElementsByTagName('head')[0].appendChild(node);
  }

  private setWindowWidth(): void {
    this.windowWidth = window.innerWidth;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.cfsConnectionService.destroySubscriptions();
  }
}
