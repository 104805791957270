import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

import { CallbackComponent } from "./account/callback.component";
import { AuthGuard } from "./core/services/auth-guard";
import { LandingGuard } from "./core/services/landing.guard";
import { DashboardComponent } from "./dashboard";
import { LandingComponent } from "./landing/pages/landing/landing.component";
import { CardVerifyComponent } from "./pages/card-verify/card-verify.component";
import { AccessDeniedComponent } from "./shared/components/access-denied/access-denied.component";
import { PendingConfigurationComponent } from "./shared/components/pending-configuration/pending-configuration.component";

const routes: Routes = [
  // { path: '', redirectTo: "/home", pathMatch:"full", canActivate: [LandingGuard] },
  {
    path: "home",
    component: LandingComponent,
    loadChildren: () => import("./landing/landing.module").then((m) => m.LandingModule),
    canActivate: [LandingGuard]
  },
  {
    path: "",
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  // { path: 'pages', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  //  canActivate: [AuthGuard] },

  {
    path: "callback",
    component: CallbackComponent
  },
  {
    path: "access-denied",
    component: AccessDeniedComponent
  },
  {
    path: "pending-configuration",
    component: PendingConfigurationComponent
  },
  {
    path: "card-verify",
    component: CardVerifyComponent,
    canActivate: [AuthGuard]
  },
  /*{
    path: 'choose-location',
    component: ChooseLocationComponent
  },*/

  // otherwise redirect to home
  { path: "**", redirectTo: "" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
